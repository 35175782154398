import { reactive } from 'vue'

import { SECTION_BLOCK } from '../../utils/constants'

import { getDataComponent } from './getDataComponent'

export const store = reactive({
  included: [],
})

export const useData = (
  response: any,
  config: { cmsBasePath: string; cloudname: string; siteLang?: string },
  t: any,
) => {
  store.included = response?.included

  const getChildren = (id: string) => {
    return response?.included
      .filter(
        (child: any) => child.attributes.behavior_settings?.layout_paragraphs?.parent_uuid === id,
      )
      .map((item: any) => {
        return getDataComponent(item, config, t)
      })
  }

  if (!response?.included) {
    return []
  }

  const sections = response?.included
    .filter((item: any) => item.type === SECTION_BLOCK)
    .map((section: any) => ({
      background: section.attributes.field_background || 'white',
      space: section.attributes.field_space || 'with-space',
      children: getChildren(section.id),
    }))

  return sections
}
