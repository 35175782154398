<template>
  <Container class="flex max-w-4xl flex-col space-y-14 py-12 lg:space-y-20 lg:py-24">
    <div v-for="(item, index) in data.cards" :key="index">
      <Title v-if="item?.title" custom-style="mb-4 lg:mb-7">{{ item?.title }}</Title>
      <div class="-mx-1 flex flex-wrap lg:-mx-2">
        <NuxtLink
          v-for="(string, i) in item.strings"
          :key="string + i"
          :to="getSearchLink(string)"
          class="m-1 flex shrink-0 items-center rounded-full border border-blue bg-white px-4 py-2.5 text-blue hover:border-blue-dark hover:text-blue-dark lg:m-2"
          @click="goToTop"
        >
          <span class="mr-4 font-sans text-base font-bold uppercase">
            {{ string }}
          </span>
          <SearchIcon />
        </NuxtLink>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
import Container from '../atoms/Container.vue'
import Title from '../atoms/Title.vue'
import SearchIcon from '../atoms/Icons/Search.vue'

export interface ISearchStringsBlock {
  id: string
  siteLang: string
  cards?: {
    strings?: string[]
    title?: string
  }[]
}

interface Props {
  data: ISearchStringsBlock
}

const props = defineProps<Props>()

const getSearchLink = (text: string): string => {
  const base = props.data.siteLang === 'sv' ? 'sok' : 'search'
  return `/${base}?text=${encodeURIComponent(text)}`
}

const goToTop = () => {
  window.scrollTo(0, 0)
}
</script>
