<template>
  <template v-for="section in sections" :key="section.id">
    <Section v-if="section.children" :background="section.background" :space="section.space">
      <TheComponents v-if="section.children" :components="section.children" />
    </Section>
  </template>
</template>

<script setup>
import { Section } from 'refresh-ui'
import TheComponents from '@/components/TheComponents.vue'
import { useRuntimeConfig } from '#app'
import { useI18n } from '#imports'
import { useData } from 'refresh-common'

/* import Cookies from 'universal-cookie';
const cookieList = new Cookies().getAll();
const sessKey = Object.keys(cookieList).find(key => key.startsWith('SSESS'));
console.log('sess', sessKey); */

// const all = cookieList.getAll();
// const all = cookies.getAll();
// counter.value = counter.value || Math.round(Math.random() * 1000);

// const { getAll } = useCookie();
// const coke = getAll();
// console.log(cookies);
// console.log(all);
const { t } = useI18n()
const config = useRuntimeConfig()

const props = defineProps({
  resource: Object,
})

const sections = useData(
  props.resource,
  {
    cmsBasePath: config.public.cmsBasePath,
    cloudname: config.public.cloudname,
    siteLang: config.public.siteLang,
  },
  t,
)
</script>
